import { createApp } from 'vue'
import { createPinia } from 'pinia'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

import App from './App.vue'
import router from './routes'

library.add(fas)

//createApp(App).mount('#app')


const app = createApp(App)
app.use(router)
app.use(createPinia())
app.component("font-awesome-icon", FontAwesomeIcon)
app.use()
app.mount('#app')
