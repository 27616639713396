<template>
    <DefaultLayout>
        <DefaultHeader :showHeader = false />
        <div id="content" class="container">
            <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-5 col-lg-5 center-block">
                    <div class="well no-padding">
                        <form  class="smart-form client-form">
                            <input type="hidden" name="activation" value="#rc.activation#">
                            <header>
                                {{ this.labels.headerText }}
                            </header>
                            <fieldset>
                                <section>
                                    <div v-if="this.error.length > 0" class="error-msg fade in">
                                        {{ this.error }}
                                    </div>
                                </section>
                                <section>
                                    <label class="input">{{ this.labels.employeeNumber }}</label>
                                    <label class="input">
                                        <input class="form-control" type="text" v-model="employeeNumber">
                                        <b class="tooltip tooltip-bottom-right">{{ this.labels.verifyAccount }}</b>
                                    </label>
                                    <span class="input-icon"><font-awesome-icon icon="file-lines" /></span>
                                </section>
                                <section>
                                    <label class="input">{{ this.labels.firstName }}</label>
                                    <label class="input">
                                        <input class="form-control" type="text" v-model="firstName">
                                    </label>
                                </section>
                                <section>
                                    <label class="input">{{ this.labels.lastName }}</label>
                                    <label class="input">
                                        <input class="form-control" type="text" v-model="lastName">
                                    </label>
                                </section>
                                <section>
                                    <label class="input">{{ this.labels.dob }}</label>
                                    <label class="input">
                                        <input class="form-control" type="date" v-model="dateOfBirth">
                                    </label>
                                </section>
                            </fieldset>
                            <footer>
                                <button @click="activateUser" class="btn btn-primary">
                                    {{ this.labels.register }}
                                </button>
                            </footer>
                            <div class="message">
                                <span class="input-icon"><font-awesome-icon icon="check" /></span>
                                <p>
                                   {{ this.labels.thanks }}
                                </p>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </DefaultLayout>    
</template>

<script>
import DefaultLayout from '../layouts/DefaultLayout.vue'
import DefaultHeader from '../layouts/DefaultHeader.vue'
//import {useRouter} from 'vue-router'
//import { store } from '../stores/store.js'
//import axiosSrv from '../interceptors/axiosSrv'
import axios from 'axios'

export default {
    name: 'Register2Page',
    components: {
        DefaultLayout,
        DefaultHeader,
    },
    data() {
        return {
            lang: this.$route.query.lang,
            userId: this.$route.query.userId,
            firstName: this.$route.query.firstName,
            lastName: this.$route.query.lastName,
            employeeNumber: '',
            dateOfBirth: '',
            error: '',

            labels: {
                headerText: '',
                employeeNumber: '',
                dob: '',
                verifyAccount: '',
                register: '',
                thanks: '',
                firstName: '',
                lastName: '',
            }
        }
    },
    mounted() {

        this.assignLabels();

    },
    methods: {

        assignLabels: function() {

            if (this.lang == 'en') {
                this.labels.headerText = 'Registration - Step 2';
                this.labels.verifyAccount = 'Needed to verify your account';
                this.labels.employeeNumber = 'Employee Number';
                this.labels.dob = 'Date of Birth';
                this.labels.register = 'Register';
                this.labels.thanks = 'Thank you for your registration!';
                this.labels.firstName = 'First Name (Payroll)';
                this.labels.lastName = 'Last Name (Payroll)';
            }
            else {
                this.labels.headerText = 'Registro - Paso 2'; 
                this.labels.verifyAccount = 'Necesario para verificar tu cuenta';
                this.labels.employeeNumber = 'Numero de empleado';
                this.labels.dob = 'Fecha de Nacimiento';
                this.labels.register = 'Registrar';
                this.labels.thanks = 'Gracias por su registroz';
                this.labels.firstName = 'Nombre (nómina)';
                this.labels.lastName = 'Apellido (Nómina)';

            }
        },
        activateUser: function (event) {

            event.preventDefault();
            
            this.error = '';

            //axios.post('/proxyapi/postApi', { path: "TpsApi/SharpUser/activateUser", userId: this.userId,  employeeNumber: this.employeeNumber, dateOfBirth: this.dateOfBirth, lang: this.lang })

            axios.post('/sharpUser/activateUser', { userId: this.userId, firstName: this.firstName, lastName: this.lastName, employeeNumber: this.employeeNumber, dateOfBirth: this.dateOfBirth, lang: this.lang })
                             .then((response) => {
                    if (response.data) {
                        let msg = this.lang == 'en' ? "Thank you. You have successfully registered. You can now login using your credentials." :
                                                 "Gracias. Usted ha sido registrado con &eacute;xito. Ahora puede entrar usando sus credenciales."
                        this.$router.push({ name: "Login", state: { lang: this.lang, hasError: false, message: msg }});
                    }
                    else {
                        let msg = this.lang == 'en' ? "We could not activate your account based on the information you have provided." :
                                                 "No pudimos activar tu cuenta basado en la información que as proveído."
                        this.$router.push({ name: "Thanks", state: { lang: this.lang, hasError: true, message: msg }});
                    }
 
                })
                .catch ( (error) => {

                    console.log('Error');
                    console.log(error);

                    this.error = error.response.data;
                                    
                })                  


        //    axiosSrv.post('SharpUser/activateUser', { "userId": this.userId,  "employeeNumber": this.employeeNumber, "dateOfBirth": this.dateOfBirth, "lang": this.lang})
        //        .then((response) => {
        //            if (response.data) {
        //                let msg = this.lang == 'en' ? "Thank you. You have successfully registered. You can now login using your credentials." :
        //                                         "Gracias. Usted ha sido registrado con &eacute;xito. Ahora puede entrar usando sus credenciales."
        //                this.$router.push({ name: "Login", state: { lang: this.lang, hasError: false, message: msg }});
        //            }
        //            else {
        //                let msg = this.lang == 'en' ? "We could not activate your account based on the information you have provided." :
        //                                         "No pudimos activar tu cuenta basado en la información que as proveído."
        //                this.$router.push({ name: "Thanks", state: { lang: this.lang, hasError: true, message: msg }});
        //            }
 
        //        })
        //        .catch ( (error) => {

        //            this.error = error;
                                    
        //        })                  
        }
        
    }
}
</script>