<template>
    <header id="header">
        <div id="logo-group">
            <span id="logo">
                <a href="#">
                    <img src="../assets/img/logos/SiteLogoCurrent.jpg?v2" alt="Site Logo">
                </a>
            </span>
        </div> 
        <div v-show="showHeader && isApprover">
            <nav class="navbar navbar-expand-lg navbar-dark bg-dark">
                <div class="container-fluid">
                    <div class="collapse navbar-collapse" id="navbarNav">
                        <ul class="navbar-nav">
                            <li class="nav-item">
                                <router-link  :to="{ name: 'SiteFocusView', query: { lang: 'en'}}" exact>View Entries</router-link>
                            </li>
                            <li class="nav-item">
                                <router-link  :to="{ name: 'SharpEntry', query: { lang: 'en'}}" exact>Create Entry</router-link>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </div>
        <span v-show="showHeader" id="extr-page-header-space">
            <router-link to="/" class="btn btn-danger">Log Out</router-link>
        </span>
    </header>
</template>
<script>

    export default {
        name: 'DefaultHeader',
        components: {

        },
        props: {
            showHeader: Boolean,
            language: String,
            isApprover: Boolean,
        },
    }
</script>
<style>
    .navbar {
        margin-top: 15px;
    }
    nav ul li a {
        font-size: 16px;
        color: blue !important;
    }
        nav ul li a:hover {
            background-color: blue !important;
            color: white !important;
        }
    
</style>
