import { reactive } from 'vue'

export const store = reactive( {
    username: '',
    firstName: '',
    lastName: '',
    employeeNumber: '',
    supervisorEmployeeNumber: '',
    supervisorFirstName: '',
    supervisorLastName: '',
    company: '',
    isAuthenticated: false,  
    isApprover: false,
})

