<template>
    <k-dialog @close="cancel">
        <div class="k-form k-form-horizontal">
            <div class="k-form-field">
                <label for="employeeFirstName" class="k-form-label">First Name</label>
                <div class="k-form-field-wrap">
                    <k-input :style="{ width: '600px' }" type="text" :readonly=false :name="'employeeFirstName'"
                             v-model="entryInEdit.employeeFirstName"></k-input>
                </div>
            </div>
            <div class="k-form-field">
                <label for="employeeLastName" class="k-form-label">Last Name</label>
                <div class="k-form-field-wrap">
                    <k-input :style="{ width: '600px' }" type="text" :readonly=true :name="'employeeLastName'"
                             v-model="entryInEdit.employeeLastName"></k-input>
                </div>
            </div>
            <div class="k-form-field">
                <label for="supervisor" class="k-form-label">Supervisor</label>
                <div class="k-form-field-wrap">
                    <k-input :style="{ width: '600px' }" type="text" :readonly=true :name="'supervisor'"
                             v-model="entryInEdit.supervisor"></k-input>
                </div>
            </div>
            <div class="k-form-field">
                <label for="company" class="k-form-label">Company</label>
                <div class="k-form-field-wrap">
                    <k-input :style="{ width: '600px' }" type="text" :readonly=true :name="'company'"
                             v-model="entryInEdit.company"></k-input>
                </div>
            </div>
            <div class="k-form-field">
                <label for="branchName" class="k-form-label">Branch</label>
                <div class="k-form-field-wrap">
                    <k-input :style="{ width: '600px' }" type="text" :readonly=true :name="'branchName'"
                             v-model="entryInEdit.branchName"></k-input>
                </div>
            </div>
            <div class="k-form-field">
                <label for="department" class="k-form-label">Department</label>
                <div class="k-form-field-wrap">
                    <k-input :style="{ width: '600px' }" type="text" :readonly=true :name="'department'"
                             v-model="entryInEdit.department"></k-input>
                </div>
            </div>
            <div class="k-form-field">
                <label for="area" class="k-form-label">Area</label>
                <div class="k-form-field-wrap">
                    <k-input :style="{ width: '600px' }" type="text" :readonly=true :name="'area'"
                             v-model="entryInEdit.area"></k-input>
                </div>
            </div>
            <div class="k-form-field">
                <label for="equipment" class="k-form-label">Equipment</label>
                <div class="k-form-field-wrap">
                    <k-input :style="{ width: '600px' }" type="text" :readonly=true :name="'equipment'"
                             v-model="entryInEdit.equipment"></k-input>
                </div>
            </div>
            <div class="k-form-field">
                <label for="dateSubmitted1" class="k-form-label">Date of Event</label>
                <div class="k-form-field-wrap">
                    <k-input :style="{ width: '600px' }" type="text" :name="'dateSubmitted1'"
                             v-model="entryInEdit.dateSubmitted1"></k-input>
                </div>
            </div>
            <div class="k-form-field">
                <label for="timeSubmitted" class="k-form-label">Time of Event</label>
                <div class="k-form-field-wrap">
                    <k-input :style="{ width: '600px' }" type="text" :name="'timeSubmitted'"
                             v-model="entryInEdit.timeSubmitted"></k-input>
                </div>
            </div>
            <div class="k-form-field">
                <label for="reason" class="k-form-label">Category</label>
                <div class="k-form-field-wrap">
                    <k-input :style="{ width: '600px' }" type="text" :readonly=true :name="'reason'"
                             v-model="entryInEdit.reason"></k-input>
                </div>
            </div>
            <div class="k-form-field">
                <label for="nearMiss" class="k-form-label">Near Miss</label>
                <div class="k-form-field-wrap">
                    <k-input :style="{ width: '600px' }" type="text" :name="'nearMiss'" :readonly=true
                             v-model="entryInEdit.nearMiss"></k-input>
                </div>
            </div>
            <div class="k-form-field" >
                <label for="nearMissType" class="k-form-label">Near Miss Type</label>
                <div class="k-form-field-wrap">
                    <k-input :style="{ width: '600px' }" type="text" :name="'nearMissType'" :readonly=true
                             v-model="entryInEdit.nearMissType"></k-input>
                </div>
            </div>
            <!--<div class="k-form-field">
                <label for="name" class="k-form-label"></label>
                <div class="k-form-field-wrap">
                    <k-input :style="{ width: '600px' }" type="text" :name="'name'"
                             v-model="entryInEdit.name"></k-input>
                </div>
            </div>-->
            <div class="k-form-field">
                <label for="description" class="k-form-label">Description</label>
                <div class="k-form-field-wrap">
                    <k-textarea :style="{ width: '600px', minHeight: '80px'  }" :readonly=true :name="'description'"
                                fillmode='solid' :rounded="large" size="large"
                                v-model="entryInEdit.description"></k-textarea>
                </div>
            </div>
            <div class="k-form-field">
                <label for="correctiveAction" class="k-form-label">Corrective Action</label>
                <div class="k-form-field-wrap">
                    <k-textarea :style="{ width: '600px', minHeight: '80px'  }" :readonly=true :name="'correctiveAction'"
                                fillmode='solid' :rounded="large" size="large"
                                v-model="entryInEdit.correctiveAction"></k-textarea>
                </div>
            </div>
            <div class="k-form-field">
                <label for="isApproved" class="k-form-label">Approved?</label>
                <div class="k-form-field-wrap">
                    <k-checkbox :name="'isApproved'" class="k-checkbox-lg"
                                v-model="entryInEdit.isApproved"></k-checkbox>
                </div>
            </div>
        </div>
        <dialog-actions-bar :layout="'start'">
            <kbutton :theme-color="'primary'" @click="save" :svg-icon="saveIcon"> Save </kbutton>&nbsp;
            <kbutton :theme-color="'warning'" @click="reject" :svg-icon="trashIcon"> Reject </kbutton>&nbsp;
            <kbutton @click="cancel" :svg-icon="cancelIcon"> Cancel </kbutton>
        </dialog-actions-bar>
    </k-dialog>



</template>

<script>
    import { Dialog, DialogActionsBar } from '@progress/kendo-vue-dialogs';
    import { Input, Checkbox, TextArea } from '@progress/kendo-vue-inputs';    
    //import { Label } from '@progress/kendo-vue-labels';
    import { Button } from '@progress/kendo-vue-buttons';
    import { cancelIcon, saveIcon, trashIcon } from '@progress/kendo-svg-icons';


    export default {
        components: {
            'k-input': Input,
            'k-textarea': TextArea,
            'k-dialog': Dialog,
            'dialog-actions-bar': DialogActionsBar,
            kbutton: Button,
            'k-checkbox': Checkbox,
          },
        props: {
            dataItem: Object,
        },
        data: function () {
            return {
                cancelIcon,
                saveIcon,
                trashIcon,
                entryInEdit: {
                    id: 0,
                    employeeNumber: '',
                    employeeFirstName: '',
                    employeeLastName: '',
                    supervisor: '',
                    reason: '',
                    name: '',
                    description: '',
                    dateSubmitted1: '',
                    whsCode: '',
                    company: '',
                    area: '',
                    equipment: '',
                    nearMiss: '',
                    nearMissType: '',
                    correctiveAction: '',
                    branchName: '',
                    department: '',                   
                    isApproved: true,
                },
            };
        },
        created: function () {
            if (this.$props.dataItem) {
                this.entryInEdit = this.$props.dataItem;
            }
        },
        methods: {
            save() {
                this.$emit('save');
            },
            reject() {
                this.$emit('reject');
            },
            cancel() {
                this.$emit('cancel');
            },
        }
    }
</script>