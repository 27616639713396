<template>
    <DefaultLayout>
        <DefaultHeader :showHeader = false />
        <div id="content" class="container">
        <div class="row">
            <div class="col-xs-12 col-sm-12 col-md-5 col-lg-4 center-block">
                <div class="well no-padding">
                    <form class="smart-form client-form">                        
                        <header>
                            {{ this.labels.headerText }}
                        </header>
                        <fieldset>
                            <section>
                                <div v-if="this.error.length > 0"  class="error-msg fade in">
                                    {{ this.error }}
                                </div>
                            </section>
                            <section>
                                <label class="label">{{ this.labels.username }}</label>
                                <label class="input"> 
                                    <input type="email" v-model="username">
                                    <b class="tooltip tooltip-top-right">{{ this.labels.tooltipUsername }}</b>
                                </label>
                                <span class="input-icon"><font-awesome-icon icon="user" /></span>
                                <div class="note">
                                    <RouterLink :to="{ name: 'Login', state: { lang: 'en', hasError: false, message: '' }}">
                                        {{ this.labels.rememberPassword }}
                                    </RouterLink>
                                </div>
                            </section>
                        </fieldset>
                        <footer>
                            <button @click="resetPassword" class="btn btn-primary">
                                {{ this.labels.ressetPassword}}
                            </button>
                        </footer>
                    </form>
                </div>
            </div>
        </div>
    </div>
    </DefaultLayout>
</template>

<script>
import DefaultHeader from '../layouts/DefaultHeader.vue'
import DefaultLayout from '../layouts/DefaultLayout.vue'
//import axiosSrv from '../interceptors/axiosSrv'
import axios from 'axios'

export default {
    name: "ForgotPassword",
    components: {
        DefaultHeader,
        DefaultLayout,  
    },
    data() {
        return {            
            lang: '' ,      
            username: '',
            error: '',

            labels: {
                headerText: '',
                username: '',
                tooltipUsername: '',
                rememberPassword: '',
                ressetPassword: '',               
            }
        }
    },
    mounted() {

        this.lang = history.state.lang;
        this.assignLabels();

    },
    methods: {

        assignLabels: function () {

             if (this.lang == 'en') {
                this.labels.headerText = 'Forgot Password';
                this.labels.username = 'Enter your email address';
                this.labels.tooltipUsername = 'Please enter email address for password reset';
                this.labels.rememberPassword = 'I remembered my password!';
                this.labels.ressetPassword = 'Reset Password';
            }
            else {
                this.labels.headerText = 'Olvidaste Contrase&ntilde;a';
                this.labels.username = 'Pon tu direcci&oacute;n de correo';
                this.labels.tooltipUsername = 'Por favor, pon tu direcci&oacute;n de correo';
                this.labels.rememberPassword = 'Ya recorde mi contrase&ntilde;a';
                this.labels.ressetPassword = 'Restablecer la Contrase&ntilde;a';
            }
        },
        resetPassword: function(event) {

            console.log('reset');

            event.preventDefault();

            //axios.post('/proxyapi/postApi', { path: "TpsApi/SharpUser/userExists", username: this.username })

            axios.post('/sharpUser/userExists', { username: this.username })
                .then((response) => {
                    if (response.data) {
                        this.forgotPassword();
                    }
                    else {
                        this.error = this.lang == 'en' ? "Sorry, that username doesn't exist." : "Lo sentimos, ese nombre de usuario no existe.";
                    }
                })
                .catch((error) => {
                    this.error = error;
                })     

                //axiosSrv.post('SharpUser/userExists', { "username": this.username})
                //    .then((response) => {
                //        if (response.data) {
                //            this.forgotPassword();                        
                //        }
                //        else {
                //            this.error = this.lang == 'en' ? "Sorry, that username doesn't exist." : "Lo sentimos, ese nombre de usuario no existe.";
                //        }                  
                //    })
                //    .catch ( (error) => {
                //        this.error = error;                    
                //    })                  

            },
            forgotPassword: function() {

                //axios.post('/proxyapi/postApi', { path: "TpsApi/SharpUser/forgotPassword", username: this.username, lang: this.lang, baseUrl: window.location.origin })

                axios.post('/sharpUser/forgotPassword', { username: this.username, lang: this.lang, baseUrl: window.location.origin })
                    .then((response) => {

                        if (response.data) {
                            let msg = this.lang == 'en' ? "Please, check your email for password reset link." :
                                "Por favor, revise su correo electrónico con el enlace para restablecer su contrase&ntilde;a."

                            this.$router.push({ name: "Thanks", state: { lang: this.lang, hasError: false, message: msg } });
                        }
                        else {
                            this.error = this.lang == 'en' ? "Error sending email." : "Error al enviar correo electrónico";
                        }
                    })
                    .catch((error) => {
                        this.error = error;
                    })   

            //axiosSrv.post('SharpUser/forgotPassword', { "username": this.username, "lang": this.lang, "baseUrl": window.location.origin })
            //    .then((response) => {

            //        if (response.data) {
            //            let msg = this.lang == 'en' ? "Please, check your email for password reset link." :
            //                "Por favor, revise su correo electrónico con el enlace para restablecer su contrase&ntilde;a."

            //            this.$router.push({ name: "Thanks", state: { lang: this.lang, hasError: false, message: msg } });
            //        }
            //        else {
            //            this.error = this.lang == 'en' ? "Error sending email." : "Error al enviar correo electrónico";
            //        }
            //    })
            //    .catch ( (error) => {                   
            //        this.error = error;                  
            //    })   

         
        }
    }

}
</script>