<template>
    <div class="animated fadeInDown desktop-detected">
        <slot></slot>
    </div>
</template>

<script>
export default {
  name: 'DefaultLayout'
}
</script>