<template>
    <DefaultLayout>
        <DefaultHeader :showHeader = false />
        <div id="content" class="container">
            <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-5 col-lg-5 center-block">
                    <div class="well no-padding">
                        <form class="smart-form client-form">
                            <header>
                            {{ headerText }}
                            </header>
                            <fieldset>
                                <div :class="{'alert': true, 'alert-danger': this.hasError, 'alert-success': !this.hasError, 'fade in': true}">								
                                {{  this.message }}
                                </div>
                            </fieldset>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </DefaultLayout>
</template>

<script>
import DefaultHeader from '../layouts/DefaultHeader.vue'
import DefaultLayout from '../layouts/DefaultLayout.vue'

export default {
    name: "ThanksPage",
    components: {
        DefaultHeader,
        DefaultLayout,  
    },
    data() {
        return {            
            lang: '' ,
            hasError: false,
            message: '',          
            headerText: '',
        }
    },
    mounted() {
        this.lang = history.state.lang;
        this.hasError = history.state.hasError;
        this.message = history.state.message;
        this.headerText = this.lang == 'en' ? "Registration" : "Registro";
    },
    setup () {
    }
}

</script>