<template>
    <DefaultLayout>
        <DefaultHeader :showHeader = false />

        <div id="content" class="container">
            <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-5 col-lg-5 center-block">
                    <div class="well no-padding">
                        <form  class="smart-form client-form" >
                            <header>
                                {{  this.labels.headerText }}
                            </header>
                            <fieldset>
                                <section>
                                    <div v-if="this.error.length > 0"  class="error-msg fade in">
                                        {{ this.error }}
                                    </div>
                                </section>
                                <section>
                                    <label class="label">{{ this.labels.newPassword }}</label>
                                    <label class="input">
                                        <input type="password" name="password" placeholder="Password" id="password" v-model="password">
                                        <b class="tooltip tooltip-bottom-right">{{ this.labels.tooltipPassword }}</b> 
                                    </label>
                                    <span class="input-icon"><font-awesome-icon icon="lock" /></span>
                                </section>
                                <section>
                                    <label class="label">{{ this.labels.confirmPassword }} </label>
                                    <label class="input">
                                        <input type="password" name="passwordConfirm" placeholder="Confirm password" v-model="passwordConfirm" >
                                        <b class="tooltip tooltip-bottom-right">{{ this.labels.tooltipPassword }}</b>
                                    </label>
                                    <span class="input-icon"><font-awesome-icon icon="lock" /></span>
                                </section>
                                <section>
                                    <div class="note">
                                        <RouterLink :to="{ name: 'Login', state: { lang: 'en', hasError: false, message: '' }}">
                                            {{ this.labels.rememberPassword }}
                                        </RouterLink>
                                    </div>
                                </section>
                            </fieldset>
                            <footer>
                                <button @click="resetPassword" class="btn btn-primary">
                                    {{ this.labels.ressetPassword }}
                                </button>
                            </footer>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </DefaultLayout>d
</template>
<script>
import DefaultHeader from '../layouts/DefaultHeader.vue'
import DefaultLayout from '../layouts/DefaultLayout.vue'
//import axiosSrv from '../interceptors/axiosSrv'
import axios from 'axios'
export default {
    name: "ForgotPassword2",
    components: {
        DefaultHeader,
        DefaultLayout,
    },
    data() {
        return {
            lang: this.$route.query.lang,
            userId: this.$route.query.userId,
            password: '',
            passwordConfirm: '',
            error: '',
            labels: {
                headerText: '',
                newPassword: '',
                confirmPassword: '',
                tooltipPassword: '',
                rememberPassword: '',
                ressetPassword: '',
            }
        }
    },
    mounted() {
    
        this.assignLabels();
    },
    methods: {

        assignLabels: function () {
            if (this.lang == 'en') {
                this.labels.headerText = 'Reset Password';
                this.labels.newPassword = 'Enter your new password';
                this.labels.tooltipPassword = 'Don\'t forget your password';
                this.labels.confirmPassword = 'Confirm your new password';
                this.labels.rememberPassword = 'I remembered my password!';
                this.labels.ressetPassword = 'Reset Password';
            }
            else {
                this.labels.headerText = 'Restablecer la contrase&ntilde;a';
                this.labels.newPassword = 'Introduzca su nueva contrase&ntilde;a';
                this.labels.tooltipPassword = 'No olvides tu contraseña';
                this.labels.confirmPassword = 'Confirma tu nueva contrase&ntilde;a';
                this.labels.rememberPassword = 'Ya recorde mi contrase&ntilde;a';
                this.labels.ressetPassword = 'Restablecer la Contrase&ntilde;a';
            }

        },
        resetPassword: function(event) {
            event.preventDefault();
            console.log('reset');
            this.error = '';
            if (this.password != this.passwordConfirm) {
                this.error = "Confirm password does not match!";
                return;
            }
            //axios.post('/proxyapi/postApi', { path: "TpsApi/SharpUser/updatePassword",  userId: this.userId, password: this.password})

            axios.post('/sharpUser/updatePassword', { userId: this.userId, password: this.password})
                            .then((response) => {
                if (response.data) {
                    let msg = this.lang == 'en' ? "Thanks, your password was successfully updated. Please, login with your new password." :
                        "Gracias, su contraseña se actualizó correctamente. Por favor, inicie sesión con su nueva contraseña."
                    this.$router.push({ name: "Login", state: { lang: this.lang, hasError: false, message: msg } });
                }
                else {
                    this.error = this.lang == 'en' ? "Error changing password." : "Error al cambiar la contraseña";
                }
            })
            .catch ( (error) => {
                this.error = error;
            })

        }
    }
}
</script>
