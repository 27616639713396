<template>
    <DefaultLayout>
        <DefaultHeader :showHeader = false />
        <div v-if="this.lang == 'en'" class="alert alert-info fade in">
            Registration is a 2 Step process.  In Step 1, we ask for your Name, E-Mail address and password info.  In Step 2,
            we will ask you for additional information to validate your user account.
        </div>
        <div v-else class="alert alert-info fade in">
            El registro es un proceso de 2 pasos. En el Paso 1, le pedimos su nombre, compañía, dirección de correo electrónico e información de contraseña. En el Paso 2, le pediremos información adicional para validar su cuenta de usuario.
        </div>
        <!-- MAIN CONTENT -->
        <div id="content" class="container">
            <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-5 col-lg-5 center-block">
                    <div class="well no-padding">
                        <form  class="smart-form client-form" >
                            <header>
                                {{ this.labels.headerText }}
                            </header>
                            <fieldset>
                                <section>
                                    <div v-if="this.error.length > 0"  class="error-msg fade in">
                                        {{ this.error }}
                                    </div>
                                </section>
                                <section>
                                    <label class="input">
                                        <input type="text" name="firstName" :placeholder="labels.firstName" v-model="firstName">
                                        <b class="tooltip tooltip-bottom-right">{{ this.labels.verifyAccount }}</b> 
                                    </label>
                                    <span class="input-icon"><font-awesome-icon icon="user" /></span> 
                                </section>
                                <section>
                                    <label class="input">
                                        <input type="text" name="lastName" :placeholder="labels.lastName" v-model="lastName">
                                        <b class="tooltip tooltip-bottom-right">{{ this.labels.verifyAccount }}</b> 
                                    </label>
                                    <span class="input-icon"><font-awesome-icon icon="user" /></span> 
                                </section>
                            </fieldset>
                            <fieldset>
                                <section>
                                    <label class="input">
                                        <input type="email" name="userName" :placeholder="labels.email" v-model="emailAddress">
                                        <b class="tooltip tooltip-bottom-right">{{ this.labels.verifyAccount }}</b> 
                                    </label>
                                    <span class="input-icon"><font-awesome-icon icon="envelope" /></span> 
                                </section>
                                <section>
                                    <label class="input">
                                        <input type="password" name="password" :placeholder="labels.password" id="password" v-model="password">
                                        <b class="tooltip tooltip-bottom-right">{{ this.labels.passwordMsg }}</b> 
                                    </label>
                                    <span class="input-icon"><font-awesome-icon icon="lock" /></span>
                                </section>

                                <section>
                                    <label class="input"> 
                                        <input type="password" name="passwordConfirm" :placeholder="labels.confirmPassword" v-model="passwordConfirm" >
                                        <b class="tooltip tooltip-bottom-right">{{ this.labels.passwordMsg }}</b> 
                                    </label>
                                    <span class="input-icon"><font-awesome-icon icon="lock" /></span>
                                </section>
                            </fieldset>

                            <fieldset>
                                <section>
                                    <label class="input">
                                        <label class="select">
                                            <select v-if="this.lang == 'en'"  v-model="securityQuestion">
                                                <option value="0" selected="" disabled="">Select Security Question</option>
                                                <option value="What is the last name of your first boss?">What is the last name of your first boss?</option>
                                                <option value="What is your favorite athlete's first name?">What is your favorite athlete's first name?</option>
                                                <option value="What is your favorite board game?">What is your favorite board game?</option>
                                                <option value="What is your favorite fictional character?">What is your favorite fictional character?</option>
                                                <option value="What is your Mother's middle name?">What is your Mother's middle name?</option>
                                                <option value="What teacher gave you the worst grade ever?">What teacher gave you the worst grade ever?</option>
                                                <option value="What was the name of the street you grew up on?">What was the name of the street you grew up on?</option>
                                                <option value="What was your favorite video game growing up?">What was your favorite video game growing up?</option>
                                                <option value="What was your first car?">What was your first car?</option>
                                                <option value="When you were a child, where was the farthest you traveled from home?">When you were a child, where was the farthest you traveled from home?</option>
                                                <option value="When you were young, what did you want to be when you grew up?">When you were young, what did you want to be when you grew up?</option>
                                                <option value="Who is your favorite artist?">Who is your favorite artist?</option>
                                                <option value="Who was your favorite teacher?">Who was your favorite teacher?</option>
                                            </select> 
                                            <select v-else  v-model="securityQuestion">
                                                <option value="0" selected="" disabled="">Seleccione Pregunta de Seguridad</option>
                                                <option value="¿Cual es el nombre de tu primer jefe?">¿Cual es el nombre de tu primer jefe?</option>
                                                <option value="¿Cual es el nombre de tu atleta favorito">¿Cual es el nombre de tu atleta favorito</option>
                                                <option value="¿Cual es tu juego de mesa Favorito">¿Cual es tu juego de mesa Favorito</option>
                                                <option value="¿Cuál es tu personaje de ficción favorito?">¿Cuál es tu personaje de ficción favorito?</option>
                                                <option value="¿Cuál es el segundo nombre de tu madre?">¿Cuál es el segundo nombre de tu madre?</option>
                                                <option value="¿Qué professor(a) te dio la peor calificación de la historia?">¿Qué professor(a) te dio la peor calificación de la historia?</option>
                                                <option value="¿Cómo se llamaba la calle en la que creciste?">¿Cómo se llamaba la calle en la que creciste?</option>
                                                <option value="¿Cuál fue tu videojuego favorito cuando eras pequeño?">¿Cuál fue tu videojuego favorito cuando eras pequeño?</option>
                                                <option value="¿Cuál fue su primer coche?">¿Cuál fue su primer coche?</option>
                                                <option value="¿Cuando eras un niño, ¿dónde fue lo más lejos que viajaste de casa? ">¿Cuando eras un niño, ¿dónde fue lo más lejos que viajaste de casa? </option>
                                                <option value="¿Cuando eras joven, ¿qué querías ser de mayor?">¿Cuando eras joven, ¿qué querías ser de mayor?</option>
                                                <option value="¿Quién es tu artista favorito(a)?">¿Quién es tu artista favorito(a)?</option>
                                                <option value="¿Quién fue tu professor(a) favorito?">¿Quién fue tu professor(a) favorito?</option>
                                            </select>

                                        </label>

                                    </label>
                                </section>
                                <section>
                                    <label class="input">                                      
                                        <input type="password" name="securityAnswer" :placeholder="labels.securityAnswer" v-model="securityAnswer">
                                    </label>
                                    <span class="input-icon"><font-awesome-icon icon="lock" /></span>
                                </section>
                                <!---<section>--->
                                    <!---<label class="checkbox">--->
                                        <!---<input type="checkbox" name="terms" id="terms">--->
                                        <!---<i></i>I agree with the <a href="##" data-toggle="modal" data-target="##termsModal"> Terms and Conditions </a></label>--->
                                <!---</section>--->
                            </fieldset>
                            <footer>
                                <button @click="registerUser" class="btn btn-primary">
                                    {{ this.labels.register }}
                                </button>
                            </footer>
                            <div class="message">
                                <span class="input-icon"><font-awesome-icon icon="check" /></span>
                                <p>
                                   {{ this.labels.thanks }}
                                </p>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal fade" id="termsModal" tabindex="-1" role="dialog" aria-labelledby="remoteModalLabel" aria-hidden="true" style="display: none;">
            <div class="modal-dialog">
                <div class="modal-content"><div class="modal-header">
                    <button type="button" class="close" data-dismiss="modal" aria-hidden="true">
                        X
                    </button>
                    <h4 class="modal-title" id="myModalLabel">Terms and Conditions</h4>
                </div>
                    <div class="modal-body">
                        <div class="row">
                            <div v-if="this.lang == 'en'" class="col-md-12">
                                <p>1. The Texas Pipe & Supply Company (TPS) Customer Portal ("Portal") allows registered Customers to gain access to relevant information about their relationship with TPS.</p>

                                <p>2. Access to the Portal is provided only with an appropriate combination of a user name and password that complies with TPS's policies (cumulatively, a "Password Combination"). Each Password Combination is specific to an individual and Customer agrees that no individuals shall provide their Password Combination to any other party. Further, to the extent that a Password Combination is used to transact business by a Customer or is compromised by an act of Customer or any party issued a Password Combination by Customer, Customer agrees to be fully responsible for all actions taken using such password.</p>

                                <p>3. As the Portal is provided as a discretionary service to Customer, without limiting other remedies, TPS may immediately issue a warning, temporarily suspend, indefinitely suspend or terminate any individual or Customer's access to the Portal at any time at its sole discretion.</p>

                                <p>
                                    4. Customer agrees to co
                                    mply with all applicable laws, statutes, rules and regulations relating to Customer's use of the Portal. The Portal and all materials, intangibles, code, look and feel, etc. related thereto is the intellectual property of TPS. Pursuant to these terms, TPS grants to Customer a license to display the Portal solely for its own internal business use in interacting with TPS for the purposes of reporting on Customer orders.  This license is non-excusive, non-transferable, and revocable at any time by TPS.
                                </p>

                                <p>5. The Customer acknowledges that the information depicted on the Portal may, from time to time, be incomplete or inaccurate.</p>

                                <p>6. Customer's use of the Portal is specifically subject to the terms of the Terms and Conditions of Sale document located online at: <a href="http://www.texaspipe.com/about/terms-conditions" target="_blank" rel="noopener noreferrer">http://www.texaspipe.com/about/terms-conditions</a> including without limitation all choice of law provisions, limitations of liabilities, disclaimers, indemnities, confidentiality, pricing, terms, and other terms contained therein which shall be equally applicable to any transactions undertaken or reported upon using the Portal. All information on the Portal shall be deemed to be TPS Confidential for all purposes.</p>
                            </div>
                            <div v-else class="col-md-12">
                                <p>1. The Texas Pipe & Supply Company (TPS) Customer Portal ("Portal") allows registered Customers to gain access to relevant information about their relationship with TPS.</p>

                                <p>2. Access to the Portal is provided only with an appropriate combination of a user name and password that complies with TPS's policies (cumulatively, a "Password Combination"). Each Password Combination is specific to an individual and Customer agrees that no individuals shall provide their Password Combination to any other party. Further, to the extent that a Password Combination is used to transact business by a Customer or is compromised by an act of Customer or any party issued a Password Combination by Customer, Customer agrees to be fully responsible for all actions taken using such password.</p>

                                <p>3. As the Portal is provided as a discretionary service to Customer, without limiting other remedies, TPS may immediately issue a warning, temporarily suspend, indefinitely suspend or terminate any individual or Customer's access to the Portal at any time at its sole discretion.</p>

                                <p>
                                    4. Customer agrees to co
                                    mply with all applicable laws, statutes, rules and regulations relating to Customer's use of the Portal. The Portal and all materials, intangibles, code, look and feel, etc. related thereto is the intellectual property of TPS. Pursuant to these terms, TPS grants to Customer a license to display the Portal solely for its own internal business use in interacting with TPS for the purposes of reporting on Customer orders.  This license is non-excusive, non-transferable, and revocable at any time by TPS.
                                </p>

                                <p>5. The Customer acknowledges that the information depicted on the Portal may, from time to time, be incomplete or inaccurate.</p>

                                <p>6. Customer's use of the Portal is specifically subject to the terms of the Terms and Conditions of Sale document located online at: <a href="http://www.texaspipe.com/about/terms-conditions" target="_blank" rel="noopener noreferrer">http://www.texaspipe.com/about/terms-conditions</a> including without limitation all choice of law provisions, limitations of liabilities, disclaimers, indemnities, confidentiality, pricing, terms, and other terms contained therein which shall be equally applicable to any transactions undertaken or reported upon using the Portal. All information on the Portal shall be deemed to be TPS Confidential for all purposes.</p>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-primary" data-dismiss="modal">
                            {{ this.labels.close }}
                        </button>
                    </div>
                </div>
            </div>
        </div>   
    </DefaultLayout>
</template>

<script>
import DefaultLayout from '../layouts/DefaultLayout.vue'
import DefaultHeader from '../layouts/DefaultHeader.vue'
//import axiosSrv from '../interceptors/axiosSrv'
import axios from 'axios'

export default {
    name: 'RegisterPage',
    components: {
        DefaultLayout,
        DefaultHeader,
    },
    data() {
        return {
            lang: this.$route.query.lang,
            firstName: '',
            lastName: '',
            emailAddress: '',
            password: '',
            passwordConfirm: '',
            securityQuestion: '0',
            securityAnswer: '',
            error: '',

            labels: {
                headerText: '',
                firstName: '',
                lastName: '',
                email: '',
                verifyAccount: '',
                passwordMsg: '',
                password: '',
                confirmPassword: '',
                securityAnswer: '',
                register: '',
                thanks: '',
                close: '',
            }
        }
    },
    setup() {        
    },

    mounted() {
     
     //   this.lang = this.$route.query.lang;

        this.assignLabels();

    },
    methods: {

        assignLabels: function() {
             if (this.lang == 'en') {
                 this.labels.headerText = 'Registration - Step 1';
                 this.labels.firstName = 'First Name';
                 this.labels.lastName = 'Last Name';
                 this.labels.verifyAccount = 'Needed to verify your account';
                 this.labels.passwordMsg = 'Don\'t forget your password';
                 this.labels.email = 'Email address/Username';
                 this.labels.password = 'Password';
                 this.labels.confirmPassword = 'Confirm password';
                 this.labels.securityAnswer = 'Security Answer';
                 this.labels.register = 'Register';
                 this.labels.thanks = 'Thank you for your registration!';
                 this.labels.close = 'Close';
            }
            else {
                this.labels.headerText = 'Registro - Paso 1'; 
                 this.labels.firstName = 'Primer Nomber';
                 this.labels.lastName = 'Apellido';
                 this.labels.verifyAccount = 'Necesario para verificar su cuenta';
                 this.labels.passwordMsg = 'No olvides tu contraseña';
                 this.labels.email = 'Correo electronico/Nombre de Usuario'
                 this.labels.password = 'Contraseña';
                 this.labels.confirmPassword = 'Confirme Su Contraseña';
                 this.labels.securityAnswer = 'Respuesta de Seguridad';
                 this.labels.register = 'Registrar';
                 this.labels.thanks = 'Gracias por su registro';
                 this.labels.close = 'Cerca';
            }
        },
        registerUser: function(event) {
            event.preventDefault();
          
            this.error = '';

            if (this.password != this.passwordConfirm) {
                this.error = "Confirm password does not match!";
                return;
            }

            //axios.post('/proxyapi/postApi', { "path": "TpsApi/SharpUser/userExists", "username": this.emailAddress })

            axios.post('/sharpUser/userExists', { "username": this.emailAddress })
                .then((response) => {
                    if (response.data) {
                        this.error = "User already exists";

                        console.log('data');
                        console.log(response.data);

                        //alert('user exists');
                    }
                    else {
                        this.createUser();
                    }
                    return;
                })
                .catch((error) => {
                    this.error = error;

                    console.log('error');
                    console.log(this.error);
                    return;
                })  

            //axiosSrv.post('SharpUser/userExists', { "username": this.emailAddress})
            //    .then((response) => {
            //        if (response.data) {
            //            this.error = "User already exists"; 
            //        }
            //        else {
            //           this.createUser();
            //        }                  
            //    })
            //    .catch ( (error) => {
            //        this.error = error;                    
            //    })                  
        },

        createUser : function() {

            //var model = {
            //    "path": "SharpUser/create",
            //    "userName": this.emailAddress, "firstName": this.firstName, "lastName": this.lastName, "password": this.password,
            //    "securityQuestion": this.securityQuestion, "securityAnswer": this.securityAnswer, "isActive": false,
            //    "employeeNumber": 0, "dateOfBirth": new Date(), "titanUserName": "na", "defaultLanguage": this.lang,
            //    "baseUrl": window.location.origin
            //}

            var model = {                
                userName: this.emailAddress, firstName: this.firstName, lastName: this.lastName, password: this.password, 
                securityQuestion: this.securityQuestion, securityAnswer: this.securityAnswer, isActive: false, 
                employeeNumber: 0, dateOfBirth: new Date(), titanUserName: "na", defaultLanguage: this.lang,
                baseUrl: window.location.origin                       
            }

            axios.post('/sharpUser/create', model)
                .then((response) => {

                    console.log('response');
                    console.log(response);

                    if (response.data) {
                        let msg = this.lang == 'en' ? "Please, check your email to continue your registration." :
                            "Por favor, chequea tu correo para continuar tu registración."
                        this.$router.push({ name: "Thanks", state: { lang: this.lang, hasError: false, message: msg } });
                    }
                    else {

                        this.error = this.lang == 'en' ? "Error registering user" : "Error al registrar usuario";

                        console.log('error');
                        console.log(this.error);

                        return;
                    }

                })
                .catch((error) => {
                    this.error = error;

                    console.log('error');
                    console.log(this.error);

                    return;
                })

            //axiosSrv.post('SharpUser/create', model)
            //    .then((response) => {    

            //        if (response.data) {
            //            let msg = this.lang == 'en' ? "Please, check your email to continue your registration." :
            //                                         "Por favor, chequea tu correo para continuar tu registración."
            //            this.$router.push({ name: "Thanks", state: { lang: this.lang, hasError: false, message: msg }});
            //        }
            //        else {

            //            this.error = this.lang == 'en' ? "Error registering user" : "Error al registrar usuario";
            //        }
            //    })
            //    .catch ( (error) => {
            //        this.error = error;
            //    })
        }
    }
}
</script>
