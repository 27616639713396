<template>
    <!--<HelloWorld />-->
    <RouterView />

</template>

<script>
//import HomePage from './components/HomePage.vue'
//import HelloWorld from "./components/HelloWorld.vue"

export default {
  name: 'App',
  components: {
    /*HelloWorld*/
  }
}
</script>

<style scoped>
    .logo {
        display: block;
        margin: 0 auto 2rem;
    }

    @media (min-width: 1024px) {
        .logo {
            margin: 0 2rem 0 0;
        }
    }
</style>


<!--#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}-->

