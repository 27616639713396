<template>    
    <DefaultLayout>
        <DefaultHeader :showHeader=false />
        <div id="content" class="container">
            <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 center-block">
                    <div class="well no-padding">
                        <form id="checkInForm" class="smart-form client-form" method="post" novalidate="novalidate">
                            <fieldset>
                                <section>
                                    <RouterLink :to="{ name: 'Login', state: { lang: 'en', hasError: false, message: '' }}">
                                        <a class="btn btn-success btn-lg btn-block" type="submit" id="english" href="#" style="text-decoration: none!important;">
                                            <img class="flag flag-us" src="../assets/img/blank.gif" alt="United States"> ENGLISH&nbsp;<font-awesome-icon icon="arrow-right" />
                                        </a>
                                    </RouterLink>
                                </section>
                                <section>
                                    <RouterLink :to="{ name: 'Login', state: { lang: 'es', hasError: false, message: '' }}">
                                        <a class="btn btn-success btn-lg btn-block" type="submit" id="spanish" href="#" style="text-decoration: none!important;">
                                            <img class="flag flag-mx" src="../assets/img/blank.gif" alt="Mexico"> ESPAÑOL&nbsp;<font-awesome-icon icon="arrow-right" />
                                        </a>
                                    </RouterLink>
                                </section>
                            </fieldset>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </DefaultLayout>
</template>

<script>

import DefaultHeader from '../layouts/DefaultHeader.vue'
import DefaultLayout from '../layouts/DefaultLayout.vue'


export default {

    name: "HomePage",
    components: {
        DefaultHeader,
        DefaultLayout
    },
    data() {

        return {
            pageName: this.$route.query.pageName,
        }
    },
    mounted() {
        // console.log(process.env.VUE_APP_TEST);

        //console.log('start mounted');

        if (this.pageName == "register2" || this.pageName == "forgotPassword2" ) {
            var path = '/' + this.pageName + '/?lang=' + this.$route.query.lang + '&userId=' + this.$route.query.userId + '&firstName=' + this.$route.query.firstName + '&lastName=' + this.$route.query.lastName;

            console.log('path=' + path);
            this.$router.push(path);
        }
        //console.log('after mounted');
    
    }

}
</script>

<style scoped>
    h1 {
        color: orange;
    }
</style>