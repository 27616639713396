<template>
    <header id="header">
      <div id="logo-group">
        <span id="logo">
          <a href="#">
            <img src="../assets/img/logos/SiteLogoCurrent.jpg?v2" alt="Site Logo">
          </a>
        </span>
      </div>  
      <span id="extr-page-header-space">
        <span v-if="lang=='en'" class="hidden-mobile">Need an account?</span>        
        <router-link v-if="lang=='en'" to="/register?lang=en" class="btn btn-danger">Create Account</router-link>
      </span>
      <span id="extr-page-header-space">
        <span v-if="lang=='es'" class="hidden-mobile">Necesitas una Cuenta?</span>        
        <router-link v-if="lang=='es'" to="/register?lang=es" class="btn btn-danger">Crear Cuenta</router-link>
      </span>
    </header>
</template>

<script>
export default {
  name: 'LoginHeader',
  data() {  
    return {
      
    }  
  },
  props: {
    lang: String
  }

}

</script>