import axios from "axios";

const instance = axios.create ( {
    baseURL: 'https://devwebapi.texaspipe.com/TpsApi/'
});

//instance.defaults.headers.post["Content-Type"] 
//instance.defaults.headers.common['Authorization'] = 'AUTH TOKEN FROM INSTANCE';
// https://devwebapi.texaspipe.com/TpsApi/

// https://localhost:44386/TpsApi/

//axios.defaults.headers.post['auth'] = 'sadfsdaf'
//axios.defaults.headers.get['key'] = 'dfdsfds'

instance.interceptors.request.use (config => {
    
    //config.headers['value'] = '';
    //config.data = { test: 'test '};
    return config;
});

export default instance